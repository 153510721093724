const autoImportModules = () => {
    const modules = {}

    const moduleContext = import.meta.globEager('./*.js')

    Object.keys(moduleContext).forEach(key => {
        const moduleName = key.replace(/\.\/(.*).js$/, '$1')
        modules[moduleName] = moduleContext[key].default
    })

    return modules
}

export default autoImportModules()
