import './assets/main.css'
import '@appmax_npm/ds-appmax-v3/dist/style.css'
import { createApp } from 'vue'
import { Vue3Mq } from 'vue3-mq'
import { createPinia } from 'pinia'
import { configureAppmaxDS } from '@appmax_npm/ds-appmax-v3'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import env from './config'
import formatter from './services/formatter'
import directives from './directives';

const app = createApp(App)
const pinia = createPinia()
const appmaxDS = configureAppmaxDS()


// Plugins
app.use(Vue3Mq, {
  breakpoints: {
    mobile: 0,
    tablet: 767,
    desktop: 1200
  }
})
app.use(router)
app.use(i18n)
app.use(pinia)
app.use(appmaxDS)

pinia.use(piniaPluginPersistedstate)

// Providers
app.provide('env', env)
app.provide('formatter', formatter)

// Global register directives
Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key]);
});

app.mount('#app')