import { defineStore } from 'pinia'

export const useStructureStore = defineStore('structure', {
    state: () => {
        return {
            isArticleView: false,
            childIsOpen: false,
            excludedRoutes: [
                {
                    returnBar: [
                        'first-access',
                        'login',
                        'not-found',
                        'forgot-password',
                        'recover-password',
                        'recover-feedback'
                    ]
                }
            ],
            includedRoutes: [
                {
                    titleReturnBar: [
                        'create-app-about-app',
                        'create-app-images',
                        'create-app-config-app',
                        'app-edit'
                    ],
                    footer: [
                        'login',
                        'home',
                        'forgot-password',
                        'recover-password',
                        'recover-feedback',
                        'user-create'
                    ]
                }
            ],
            darkModeRoutes: ['/', '/user/create'],
        }
    },
    actions: {
        hasInExcludedRoutes(routeName, componentType) {
            return this.excludedRoutes[0][componentType].includes(routeName)
        },
        hasInIncludedRoutes(routeName, componentType) {
            return this.includedRoutes[0][componentType].includes(routeName)
        },
        setIsArticleViewStatus(status) {
            this.isArticleView = status
        },
        setChildIsOpenStatus(status) {
            this.childIsOpen = status
        },
        isDarkMode(routePath) {
            return this.darkModeRoutes.includes(routePath);
        }
    }
})
