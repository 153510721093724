const api = {
    appbox: {
        baseURL:
            import.meta.env.VITE_APP_APPMAX_API_APPBOX_URL ||
            'http://localhost:8082',
        token: localStorage.getItem('user-token') || '',
    }
}

export default api
