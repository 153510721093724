import moment from 'moment'

const date = (date, type = 'fullTime') => {
    return {
        fullTime: moment(date).format('DD/MM/YYYY HH:mm:ss'),
        fullDate: moment(date).format('DD/MM/YYYY'),
        fullHour: moment(date).format('HH:mm:ss'),
        time: moment(date).format('HH:mm'),
        date: moment(date).format('DD/MM')
    }[type]
}

export default {
    date
}
