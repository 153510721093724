import { defineAsyncComponent } from 'vue';

const AmNavbar = defineAsyncComponent(() => import('@/components/molecules/AmNavbar/AmNavbar.vue'));
const NewAmNavBar = defineAsyncComponent(() => import('@/components/molecules/AmNavbar/NewAmNavBar.vue'));
const AmReturnBar = defineAsyncComponent(() => import('@/components/molecules/AmReturnBar/AmReturnBar.vue'));
const BackToSiteButton = defineAsyncComponent(() => import('@/views/HomeView/BackToSiteButton.vue'));

const newComponentRoutes = new Set(['/', '/user/create']);

export const getComponentForRoute = (route, componentType) => {
  const isRouteNew = newComponentRoutes.has(route.path);

  const componentMap = {
    navbar: isRouteNew ? NewAmNavBar : AmNavbar,
    returnBar: isRouteNew ? BackToSiteButton : AmReturnBar
  };

  return componentMap[componentType] || null;
};
