const app = {
    name: import.meta.env.VITE_APP_NAME || 'Vite App',
    env: import.meta.env.VITE_APP_LOCAL || 'local',
    locale: {
        default: import.meta.env.VITE_APP_I18N_LOCALE || 'pt-BR',
        fallback: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en-US'
    },
    baseURL: import.meta.env.VITE_APP_BASE_URL || 'https://appstore.sandboxappmax.com.br',
    documentation: import.meta.env.VITE_APP_DOCUMENTATION || 'https://appmax.readme.io',
}

export default app
