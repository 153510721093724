<template>
  <div :class="footerClasses">
    <div class="the_footer_informations-appmax">
      <img
        :src="logoSrc"
        alt="appmax" />
      <AmTypography
        :text="$t(`footer.appmax.credentials`)"
        :textColor="textColor"
        fontType="sarabun"
        typographyStyle="l7"
      />
    </div>
  </div>
</template>

<script>
import './AmFooter.scss'
import { AmTypography } from '@appmax_npm/ds-appmax-v3'
import { computed, inject } from 'vue'
import { useStructureStore } from '@/stores/structure';
import { useRoute } from 'vue-router'
import logoLightMode from '@/assets/logo.svg' 
import logoDarkMode from '@/assets/logo_darkmode.svg'

export default {
  name: 'AmFooter',
  components: { AmTypography },
  setup() {
    const mq = inject('mq')
    const route = useRoute()
    const structureStore = useStructureStore();
    const isDarkMode = computed(() => structureStore.isDarkMode(route.path));

    const footerClasses = computed(() => [
      'the_footer_informations',
      {
        the_footer_informations_desktop: mq.current === 'desktop',
        the_footer_informations_mobile: mq.current !== 'desktop',
        'the_footer_informations--dark': isDarkMode.value
      }
    ])

    const logoSrc = computed(() => isDarkMode.value ? logoDarkMode : logoLightMode)

    const footerText = computed(() => route.name === 'home' ? `footer.appmax.credentialsDark` : `footer.appmax.credentials`)

    const textColor = computed(() => isDarkMode.value ? 'white' : 'text120')

    return { footerClasses, logoSrc, footerText, textColor }
  }
}
</script>
