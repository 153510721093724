function handleClickInside(el) {
  return () => {
    el.dataset.isFocused = 'true';
  };
}

function handleClickOutside(el, binding) {
  return (event) => {
    const isFocused = el.dataset.isFocused === 'true';
    const clickedOutside = !(el === event.target || el.contains(event.target));

    if (isFocused && clickedOutside) {
      binding.value(event);
      el.dataset.isFocused = 'false';
    }
  };
}

export const clickOutside = {
  beforeMount(el, binding) {
    el.dataset.isFocused = 'false';

    el.__handleClickInside = handleClickInside(el);
    el.__handleClickOutside = handleClickOutside(el, binding);

    el.addEventListener('mousedown', el.__handleClickInside);
    document.addEventListener('mousedown', el.__handleClickOutside);
  },
  unmounted(el) {
    el.removeEventListener('mousedown', el.__handleClickInside);
    document.removeEventListener('mousedown', el.__handleClickOutside);
  },
};
