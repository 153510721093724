<script setup>
import AmFooter from '@/components/molecules/AmFooter/AmFooter.vue'
import { useStructureStore } from '@/stores/structure'
import { RouterView, useRoute } from 'vue-router'
import { computed, onBeforeMount, inject } from 'vue'
import _ from 'lodash'
import { useAuthStore } from './stores/auth'
import { useNavbarStore } from './stores/navbar'
import { getComponentForRoute } from '@/services/route-components'; 


const structureStore = useStructureStore()
const authStore = useAuthStore()
const navbarStore = useNavbarStore()

const route = useRoute()
const mq = inject('mq')
const showReturnBar = computed(() => {
  return !structureStore.hasInExcludedRoutes(route.name, 'returnBar') && !route.fullPath.includes('forgot-password')
})

const appClasses = computed(() => {
  return {
    'background-color-documentation': route.path.includes('documentation') || route.name === 'home',
    'dark-theme': isDarkMode.value
  }
});

const hasReturnBarBorder = computed(() => {
  return route.path.includes('documentation')
})

const hasFooter = computed(() => {
  return structureStore.hasInIncludedRoutes(route.name, 'footer') || route.fullPath.includes('forgot-password') || route.fullPath.includes('documentation')
})

const routeName = computed(() => {
  const regex = /documentation[a-zA-Z0-9-]+/g;

  if (route.name !== undefined && route.name !== null) {
    return route.path.match(regex) ? _.camelCase(route.name) : null;
  }

  return null;
});

const NavbarComponent = computed(() => getComponentForRoute(route, 'navbar'));
const ReturnBarComponent = computed(() => getComponentForRoute(route, 'returnBar'));
const isDarkMode = computed(() => structureStore.isDarkMode(route.path));

const isUserCreateRoute = computed(() => route.name === 'user-create');

const isUserCreateRouteAndSmallScreen = computed(() => {
  return isUserCreateRoute.value && (mq.current === 'mobile' || mq.current === 'tablet');
});

onBeforeMount(() => {
  authStore.isAuthenticated ? navbarStore.setPrivateMode() : navbarStore.setPublicMode()
})
</script>

<template>
  <div :class="{'dark-theme': isDarkMode}">
    <div
      id="app"
      :class="[appClasses, { 'max-container': isDarkMode }]">
      <component
        :is="NavbarComponent"
        id="navbar" />
      <component 
        :is="ReturnBarComponent"
        v-if="showReturnBar"
        id="am-return-bar"
        :title="routeName !== null ? $t(`returnBar.labels.${routeName}`) : $t('forDeveloper')"
        :class="[
          {
            'am_return_bar--border': hasReturnBarBorder && mq.current !== 'mobile'
          }
        ]"
      />
      <div
        v-if="isDarkMode && !isUserCreateRouteAndSmallScreen"
        class="light-container">
        <div class="backlight"></div>
        <div class="svg-container">
          <img src="/src/assets/homeView/purple_light.svg" />
        </div>
      </div>
      <RouterView id="main" />
      <AmFooter
        v-if="hasFooter"
        id="footer" />
    </div>
  </div>
</template>


<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .dark-theme {
    background-color: $darkModeBackground;
  }

  & .max-container {
    max-width: 1134px;
    margin: auto;
    min-height: 100vh;
  }

  #navbar {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;

    .dark-theme & {
      background-color: $darkModeNavBar;
      display: flex;
      justify-content: center;
    }
  }

  #am-return-bar {
    margin-top: 84px;
  }

  @media screen and (min-width: 768px) {
    #main {
      padding-bottom: 16px;
    }
  }

  #footer {
    margin-top: auto;
    max-height: fit-content;

    .dark-theme & {
      background-color: $darkModeBackground;
    }
  }
}

.background-color-documentation {
  background-color: #fafafa;
}

.light-container {
  position: relative;
}

.backlight {
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-47%, -67%);
  border-radius: 481px;
  background: rgba(75, 164, 198, 0.7);
  filter: blur(66px);
  width: 481px;
  height: 401px;
  z-index: 1;
  @media #{$mediaMaxWidth768} {
    left: 65%;
    width: 303px;
  }
}

.svg-container {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -52%);
  z-index: 1;
  mix-blend-mode: screen;
  filter: blur(82.3003005981px);
  @media #{$mediaMaxWidth768} {
    position: absolute;
    top: 50%;
    left: 27%;
    transform: translate(-51%, -24%);
  }
}

</style>